<template>
  <div>
    <el-page-header @back="dynamic" content="访问记录" style="margin-bottom: 15px;">
    </el-page-header>
    <el-button type="text">
      <el-button plain size="mini" @click="clearVis">清空访问记录</el-button>
    </el-button>
    <el-table
        :data="visitorData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="visIp"
          label="访问地址"
          width="150">
      </el-table-column>
      <el-table-column
          prop="visOs"
          label="操作系统"
          width="150">
      </el-table-column>
      <el-table-column
          prop="visInfo"
          label="浏览器"
          width="150">
      </el-table-column>

      <el-table-column
          prop="visAdd"
          label="归属地">
      </el-table-column>
      <el-table-column
          prop="visTime"
          label="访问时间"
          width="150">
      </el-table-column>
      <el-table-column
          label="操作"
          width="50">
        <template>
          <el-button
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="width: 100%; text-align: center; margin-top: 20px">
      <el-pagination
          background
          layout="prev, pager, next"
          :total=pages.total
          :page-size="15"
          @current-change="getVisByPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "relo",
  data() {
    return {
      formLabelWidth: '80px',
      visitorData: [],
      pages:{
        total: 0
      }
    }
  },
  mounted() {
    this.getVisitorList(1)
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    getVisitorList(pager) {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$http({
        method: 'get',
        url: '/api/visitor/getVisitorList/' + pager
      }).then((res) =>{
        this.visitorData = res.data.data
        this.pages.total = res.data.page.totalSize
        if (res.data.status) loading.close();
        setTimeout(()=>{loading.close()},500)
      })
    },
    getVisByPage(val) {
      this.getVisitorList(val)
    },
    clearVis() {

    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>